import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Modal from 'react-responsive-modal';
import { UserProfileContext, useUser } from '../../../context/userProfileContext';
import { translations } from '../Login/LoginUtils/onDemandLoginSimpleTexts';
import SimpleOnDemandModalScreen from '../OnDemand/SimplifiedOnDemandModals/SimpleOnDemandModalScreen';
import useResponsiveUtility from '../../../util/responsiveUtility';

const MODAL_STYLES = {
  modal: {
    background: 'rgba(0, 0, 0, 0)',
    boxShadow: 'none',
    width: '100vw',
    zIndex: 1005,
    // padding: '2rem',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
    zIndex: 9999,
  },
  closeButton: {
    top: '18px',
    right: '18px',
  },
};

const MODAL_STYLES_MOBILE = {
  modal: {
    background: 'rgba(0, 0, 0, 0)',
    boxShadow: 'none',
    width: '100vw',
    height: '100vh',
    overflow: 'hidden',
    padding: '0',
    zIndex: 1005,
  },
  overlay: {
    background: 'rgba(255, 255, 255, 0.68)',
    width: '100vw',
    height: '100vh',
    overflow: 'hidden',
    padding: '0',
    zIndex: 6000,
  },
  closeButton: {
    top: '18px',
    right: '18px',
    zIndex: 7000,
  },
};

const TopHeaderBanner = ({ language, postVideoUrl, postVideoRole, sourceWeb, onClose, hideCloseButton }) => {
  const { userName, token, userProps } = useUser();
  const { deviceScreen } = useResponsiveUtility();
  const userContext = useContext(UserProfileContext);
  const userAlreadySubscribed = userContext.userRoles.includes('rdl-subscription');

  const [isModalOpen, setModalOpen] = useState(false);
  const [isNavVisible, setIsNavVisible] = useState(true);

  const onOpenModal = () => {
    window.scrollTo(0, 0);
    setModalOpen(true);
  };
  const onCloseModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setIsNavVisible(currentScrollPos === 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      { isNavVisible ? (
        <div className="flex flex-end items-center w-full sm:h-42px h-12 bg-beige flex-row-reverse">
          <div className="flex items-center flex-row z-top">
            <Link to="/hemeroteca">
              <p className="font-stratos sm:text-xs text-sm font-semibold text-black px-3 border-black border-r">Hemeroteca</p>
            </Link>
            {!userAlreadySubscribed ? (
              <Link to="/suscribirse">
                <p className="font-stratos sm:text-xs text-sm text-red font-semibold px-3 border-black border-r">Suscribirse</p>
              </Link>
            ) : null }
            {token ? (
              <Link to="/user-profile">
                <span className="text-red sm:text-xs font-stratos text-sm font-semibold px-3 mr-3">{ userName ?? '' }</span>
              </Link>
            )
              : (
                <div className="flex flex-row z-top">
                  <button type="button" onClick={onOpenModal} className="font-stratos sm:text-xs text-sm font-semibold text-black px-3">
                    Iniciar Sesión
                    <span className="font-icon sm:text-xs icon-user text-black mx-2" />
                  </button>
                  <Modal open={isModalOpen} onClose={onCloseModal} center closeOnOverlayClick styles={deviceScreen !== 'sm' ? MODAL_STYLES : MODAL_STYLES_MOBILE} focusTrapped={false}>
                    <SimpleOnDemandModalScreen
                      {...userProps}
                      language={language}
                      translations={translations}
                      postVideoUrl={postVideoUrl}
                      postVideoRole={postVideoRole}
                      sourceWeb={sourceWeb}
                      hideCloseButton={hideCloseButton}
                      closeModal={onCloseModal}
                    />
                  </Modal>
                </div>
              )}
          </div>
        </div>
      ) : null}
    </>
  );
};

TopHeaderBanner.propTypes = {
  language: PropTypes.string,
};

TopHeaderBanner.defaultProps = {
  language: 'en',
};

export default TopHeaderBanner;
