/* eslint-disable eol-last */
/* eslint-disable semi */
/* eslint-disable comma-dangle */
/* eslint-disable quote-props */
/* eslint-disable quotes */
/* eslint-disable indent */

const error404Page = (appName = 'Primavera Sound') => (
  {
    "slugName": "404",
    "name": "404",
    "postCategory": [
      "404"
    ],
    "title": "404",
    "type": "route",
    "postDescription": {
      "title": {
        "en": `${appName} - Error 404`,
      },
      "description": {
        "en": `${appName} - Error 404`,
      },
      "url": ""
    },
    "components": [
      {
        "type": "Body",
        "slugName": "error404",
        "components": [
          {
            "type": "BodyChildContainer",
            "slugName": "homeBodyContainer",
            "components": [
              {
                "type": "Error404",
                "params": [
                  {
                    "name": "backstreet",
                    "title": {
                      "en": "Looks like something went wrong",
                      "es": "Parece que algo salió mal",
                      "ca": "Sembla que alguna cosa va sortir malament"
                    },
                    "text": {
                      "en": "Ooops! Show me the meaning of being lonely...",
                      "es": "Ooops! Show me the meaning of being lonely...",
                      "ca": "Ooops! Show me the meaning of being lonely..."
                    },
                    "image": "https://thumbs.gfycat.com/MelodicLiveHartebeest-size_restricted.gif"
                  },
                  {
                    "name": "justin",
                    "title": {
                      "en": "Looks like something went wrong",
                      "es": "Parece que algo salió mal",
                      "ca": "Sembla que alguna cosa va sortir malament"
                    },
                    "text": {
                      "en": "Soooorry! Is it too late now to say sorry? Yeah, we know that we let you down Is it too late to say we're sorry now?",
                      "es": "Soooorry! Is it too late now to say sorry? Yeah, we know that we let you down Is it too late to say we're sorry now?",
                      "ca": "Soooorry! Is it too late now to say sorry? Yeah, we know that we let you down Is it too late to say we're sorry now?"
                    },
                    "image": "https://66.media.tumblr.com/caaa91cc8d018fb7d1a560bdbb6b6d80/tumblr_inline_obob4pubkF1ruq2qk_500.gif"
                  },
                  {
                    "name": "radiohead",
                    "title": {
                      "en": "Looks like something went wrong",
                      "es": "Parece que algo salió mal",
                      "ca": "Sembla que alguna cosa va sortir malament"
                    },
                    "text": {
                      "en": "Ooops! What the hell are you doing here? You don't belong here...",
                      "es": "Ooops! What the hell are you doing here? You don't belong here...",
                      "ca": "Ooops! What the hell are you doing here? You don't belong here..."
                    },
                    "image": "https://thumbs.gfycat.com/SlimyHiddenAlleycat-size_restricted.gif"
                  }
                ],
                "linkButtonRounded": {
                  "name": "error404",
                  "is404": true,
                  "text": {
                    "es": "Llévame a la home",
                    "en": "Take me home",
                    "ca": "Porta'm a la home"
                  },
                  "className": "rounded-full uppercase text-base sm:text-14 md:text-14 py-2 md:px-8 px-16 sm:px-8 font-americaMonoBold text-black border-2 border-black sm:whitespace-pre",
                  "url": "/"
                }
              }
            ]
          }
        ]
      }
    ],
    "rolesAllowed": []
  }
)

export default error404Page;
