import { animateScroll as scroll } from 'react-scroll';
import userLinkSpotify from '../../../../../api/graphql/mutations/userLinkSpotify';
import addExistingUserToNewsLetter from '../../../../../api/graphql/mutations/addExistingUserToNewsLetter';
import userUnLinkSpotify from '../../../../../api/graphql/mutations/userUnLinkSpotify';
import profileUserEdit from '../../../../../api/graphql/mutations/profileUserEdit';

import { ORIGIN, SCOPES } from './profileFormConstants';

import { getCodeFromCountry } from '../../../../../util/getCountryNamesByCode';


const handleResponseSpotify = async (payload, userEmail) => {
  try {
    const result = await userLinkSpotify(payload.code, userEmail);
    return result;
  } catch (err) {
    console.log('Some error happened on fetch of fetchPostDescription:', err);
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'canSubmit':
      return { ...state, canSubmit: true };
    case 'cannotSubmit':
      return { ...state, canSubmit: false };
    case 'didSubmit':
      return { ...state, didSubmit: true };
    case 'didnotSubmit':
      return { ...state, didSubmit: false };
    case 'isAddingToNewsLetter':
      return { ...state, isAddingToNewsLetter: true };
    case 'isNotAddingToNewsLetter':
      return { ...state, isAddingToNewsLetter: false };
    case 'setError':
      return { ...state, errorMsg: action.error };
    case 'setDateOfBirth':
      return { ...state, dateOfBirth: action.dateOfBirth };

    default:
      throw new Error();
  }
};

const addUserToNewsLetter = (email, language, dispatch, loadTokenForLS) => {
  const newsletterFormData = {
    email,
    language,
    origin: ORIGIN,
    scopes: SCOPES,
  };
  if (email && language) {
    dispatch({ type: 'isAddingToNewsLetter' });
    addExistingUserToNewsLetter({ ...newsletterFormData }).then((response) => {
      if (!response.isValid) {
        // handle error (not able to add user to newsletter)
        console.log('error when trying to add user', response.error);
      } else {
        // update token in localstorage and cookies
        dispatch({ type: 'isNotAddingToNewsLetter' });
        localStorage.setItem(process.env.AUTH_TOKEN, response.token);
        document.cookie = `${process.env.AUTH_TOKEN}=${response.token}; Secure; HttpOnly`;
        loadTokenForLS();
      }
    });
  }
};

const unlinkSpotifyAccount = (userEmailFromFn, loadTokenForLS, dispatch) => {
  userUnLinkSpotify(userEmailFromFn).then((res) => {
    if (res) {
      if (res.isEdited && res.token) {
        localStorage.setItem(process.env.AUTH_TOKEN, res.token);
        document.cookie = `${process.env.AUTH_TOKEN}=${res.token}; Secure; HttpOnly`;
        loadTokenForLS();
      } else if (res.error) {
        dispatch({ type: 'setError', error: res.error });
        setTimeout(() => {
          dispatch({ type: 'setError', error: '' });
        }, 5000);
      } else {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Error - submitForm validated, but returned isValid = false!`);
      }
    } else {
      console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Error - submitForm failed to submit!`);
    }
  });
};

const submitForm = (formData, userDateOfBirth, lang, dispatch, loadTokenForLS, dateOfBirth, setDidSubmit, history, webSource = '') => {
  const dateOfBirthFinal = dateOfBirth || userDateOfBirth;

  dispatch({ type: 'canSubmit' });

  const profileFormAdj = {};
  profileFormAdj.email = formData.email;
  profileFormAdj.name = formData.userName;
  profileFormAdj.lastname = formData.userLastName;
  profileFormAdj.nickname = formData.userNickName;
  profileFormAdj.postalCode = formData.userPostalCode;
  profileFormAdj.dateOfBirth = dateOfBirthFinal;
  profileFormAdj.inputCountry = getCodeFromCountry(formData.inputCountry, lang);

  // profileFormAdj.lastname = formData.userLastName;

  profileUserEdit({ ...profileFormAdj }).then((res) => {
    if (res) {
      if (res.isEdited) {
        dispatch({ type: 'didSubmit' });
        setDidSubmit(true);
        localStorage.setItem(process.env.AUTH_TOKEN, res.token);
        document.cookie = `${process.env.AUTH_TOKEN}=${res.token}; Secure; HttpOnly`;
        loadTokenForLS();
        scroll.scrollToTop({ smooth: 'linear' });
        // setTimeout(() => {
        //   history.push(`/${lang}${webSource && `/${webSource}`}`);
        // }, 3000);
      } else if (res.error) {
        dispatch({ type: 'setError', error: res.error });
        setTimeout(() => {
          dispatch({ type: 'setError', error: '' });
        }, 5000);
      } else {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Error - submitForm validated, but returned isValid = false!`);
      }
    } else {
      console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Error - submitForm failed to submit!`);
    }
  });
};

// eslint-disable-next-line import/prefer-default-export
export {
  handleResponseSpotify, reducer, addUserToNewsLetter, unlinkSpotifyAccount, submitForm,
};
