/* eslint-disable jsx-a11y/label-has-for */
import React, { Component } from 'react';
import { propTypes, withFormsy } from 'formsy-react';
import InfoPopover from './InfoPopover';

class FormInput extends Component {
  state = {
    value: '',
    isError: false,
  };

  componentDidMount() {
    const { isError = false } = this.props;
    this.setState({
      isError,
    });
  }


  componentDidUpdate = (prevProps, prevState) => {
    const { getValue, isError } = this.props;
    if (prevProps.isError !== isError) {
      this.setState({
        isError,
      });
    }
    if (prevProps !== this.props && getValue() !== undefined) {
      if (prevState.value !== getValue()) {
        this.setState({
          value: getValue(),
        });
      }
    }
  };

  changeValue = (e) => {
    this.setState({
      value: e.currentTarget.value,
    });
  };

  validate = (e) => {
    const { setValue } = this.props;
    setValue(e.currentTarget.value);
  };

  handleIsErrorClick = () => {
    const { isError } = this.state;
    if (isError) {
      setTimeout(() => {
        this.setState({
          isError: false,
        });
      }, 500);
    }
  };

  render() {
    const {
      name,
      type,
      title,
      showError,
      getErrorMessage,
      isValid,
      required,
      doNotChangeValidColor,
      // isError = false,
      titleClassName,
      className,
      hidden = false,
      disabled = false,
      infoText = '',
      backgroundColor = 'inherit',
      icon = '',
      onChangeCallback,
      onBlurCallback,
      placeholder,
    } = this.props;

    const { value, isError = false } = this.state;

    const errorMessage = getErrorMessage();

    return (
      <div onClick={this.handleIsErrorClick} className={`font-stratos ${className || ''} ${hidden ? 'hidden' : ''}`}>
        <label className="flex flex-col items-stretch" htmlFor={name}>
          <span className="text-12 font-light text-black uppercase">
            {title}
            {/* {required && <span className="align-top text-red-light">*</span>} */}
            {infoText && <div className="ml-4"><InfoPopover text={infoText} /></div>}
          </span>
          <div className="relative mt-2">
            <input
              disabled={disabled}
              onChange={onChangeCallback || this.changeValue}
              onBlur={onBlurCallback || this.validate}
              name={name}
              type={type || 'text'}
              value={value}
              autoComplete={name}
              hidden={hidden}
              placeholder={placeholder || title}
              className={`appearance-none w-full leading-22 rounded-none border-1 placeholder-gray p-4 ${showError() || isError ? 'border-red text-red' : 'border-black text-black'} ${(disabled || icon !== '' || backgroundColor === 'beige') && 'bg-beige'} ${isValid() && value !== '' && !doNotChangeValidColor ? 'border-green text-green' : 'border-black text-black'}`}
            />
            {icon !== '' && (
              <div className="absolute pin-t pin-r pin-b flex items-center justify-center pr-3">
                <span className={`font-icon text-red text-20 ${icon}`} />
              </div>
            )}
          </div>
        </label>
        <span className="flex flex-col text-red mt-4">{errorMessage}</span>
      </div>
    );
  }
}

FormInput.propTypes = {
  ...propTypes,
};

export default withFormsy(FormInput);
