import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { buttonClasses } from '../../OnDemandUtils/onDemandUtils';
import SpotifyLogin from '../../../Login/SpotifyLogin/SpotifyLogin';
import { responseGoogle, responseFacebook } from '../../../Login/LoginUtils/handleSocialNetworkResponses';
import GoogleSSO from '../../../GoogleSSO';

const SocialOnDemandButton = ({
  type, loadTokenForLS, handleRegisteringStatus, setError,
}) => {
  const history = useHistory();
  let jsxReturned = (<span />);

  const redirectUriSpotify = window.location.origin;
  // eslint-disable-next-line default-case
  switch (type) {
    case 'google':
      jsxReturned = (<GoogleSSO handleRegisteringStatus={handleRegisteringStatus} />);
      break;
    case 'spotify':
      jsxReturned = (
        <>
          <SpotifyLogin
            icon
            buttonText=""
            redirectUri={redirectUriSpotify}
            className={`${buttonClasses({ backgroundColor: 'spotify', textSize: 'xl', width: 10, height: 10 })}  xs:text-xxs md:text-xs sm:text-xxs`}
            onSuccess={(res) => {
              const { isValid, needToRegister, userPayload, error } = res;
              if (isValid) history.push('/');
              if (error) return setError(error);
              if (needToRegister) return handleRegisteringStatus(true, userPayload, 'spotify');
            }}
            onFailure={(res) => { console.log('spotify login failed: ', res); }}
          />
        </>
      );
      break;

    case 'facebook':
      jsxReturned = (
        <FacebookLogin
          appId="2691426617774080"
          // appId="830365707464738"
          disableMobileRedirect // To avoid oauth problem with facebook on mobile
          fields="name,email,picture"
          render={renderProps => (
            <button
              type="button"
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
              className={buttonClasses({
                backgroundColor: 'facebook',
                text: 'white',
                textSize: 'xl',
                width: '10',
                height: '10',
              })}
            >
              <div className="font-icon icon-facebook" />
            </button>
          )}
          callback={async (data) => {
            const response = await responseFacebook(data);
            if (response) {
              const {
                isValid, needToRegister, userPayload, error = '',
              } = response;
              if (isValid) {
                loadTokenForLS();
                history.push('/');
                // !!! need to check this no need to handle ondemandscreenstatus as userEmail in context is being updated already
                // handleOnDemandScreenStatus('logged');
              } else {
                if (error) {
                  setError(error);
                }
                if (needToRegister) {
                  const userPayloadWithSocialNetwork = {
                    ...userPayload,
                    profileObj:
                    { ...userPayload.profileObj, isSocialLogin: type },
                  };
                  // alert("usuario no registrado completa tu registro")
                  handleRegisteringStatus(true, userPayloadWithSocialNetwork, 'facebook');
                } else {
                  console.log('**** User Not Valid by Server, Sorry ****');
                }
              }
            }
          }}
          onFailure={() => { console.log('failed'); }}
        />

      );
      break;
  }
  return jsxReturned;
};


SocialOnDemandButton.propTypes = {
  language: PropTypes.string,
  loadTokenForLS: PropTypes.func.isRequired,
  handleOnDemandScreenStatus: PropTypes.func.isRequired,
  handleRegisteringStatus: PropTypes.func.isRequired,
  sourceWeb: PropTypes.string,
};

SocialOnDemandButton.defaultTypes = {
  language: 'en',
  sourceWeb: '',
};

export default SocialOnDemandButton;
