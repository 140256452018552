import React, { useReducer } from 'react';
import PropTypes from 'prop-types';

import Formsy from 'formsy-react';
import Link from '../../../../../util/link';
import translateObj from '../../../../../util/translateObj';

import getCountryNamesByCode, { getCountryFromCode } from '../../../../../util/getCountryNamesByCode';

import { useUser } from '../../../../../context/userProfileContext';

import FormInput from '../../../Form/FormInput';
import FormSelectAutoComplete from '../../../Form/FormSelectAutoComplete';
import DatePickerFormsy from '../../DatePickerFormsy';
import SpotifyLogin from '../../SpotifyLogin/SpotifyLogin';
import LoadIcon from '../../../LoadIcon';
import InfoPopover from '../../../Form/InfoPopover';
import SimpleButton from '../../../Buttons/SimpleButton';

import {
  handleResponseSpotify,
  addUserToNewsLetter,
  unlinkSpotifyAccount,
  submitForm,
  reducer,
  updateButtonText,
  initialState,
} from '../ProfileFormUtils';

const displayNewsLetterFormButton = ({
  isUserInNewsLetter,
  isUserInSplio,
  userEmail,
  isUserVerified,
  language,
  newsLetterTermsUrl,
  newsLetterTermsText,
  newsletterInfo,
  newsLetterButtonText,
  newsLetterConfigureText,
  newsletterSettingsInfo,
  newsLetterSubscribeText,
  isAddingToNewsLetter,
  dispatch,
  loadTokenForLS,
}) => {
  if (!userEmail || (!isUserVerified && isUserInNewsLetter)) return null;


  // if (!isUserInSplio) {
  //   return (
  //     <div className="px-3 mb-4 sm:w-full w-1/2 sm:w-full md:w-full">
  //       <label className="flex flex-col" htmlFor="newsletterLink">
  //         <span className="flex">
  //           <span className="uppercase">
  //             <span>{translateObj(newsLetterSubscribeText, language)}</span>
  //             {(newsLetterTermsText && newsLetterTermsText.en && newsLetterTermsUrl && newsLetterTermsUrl.en) && (
  //             <Link className="text-black underline uppercase cursor-pointer" to={translateObj(newsLetterTermsUrl, language)}>{translateObj(newsLetterTermsText, language)}</Link>
  //             )}
  //           </span>
  //           {newsletterInfo && newsletterInfo.en && (
  //           <div className="ml-4">
  //             <InfoPopover
  //               text={translateObj(newsletterInfo, language)}
  //             />
  //           </div>
  //           )}

  //         </span>

  //         <div className="cursor-pointer rounded-full bg-inherit bg-black uppercase text-white text-16 xs:text-14 sm:text-14 font-americaMonoBold sm:static sm:mx-0 sm:w-full  text-center md:w-full mt-4 px-12 lg:px-16 xl:px-16 py-4 flex items-center  justify-center " onClick={() => addUserToNewsLetter(userEmail, language, dispatch, loadTokenForLS)}>
  //           { isAddingToNewsLetter ? <LoadIcon color="white" type="ThreeDots" height={15} width={15} />
  //           //  <div className="font-icon text-white icon-email text-18 -mt-px mr-6" />
  //             : <div className="uppercase">{translateObj(newsLetterButtonText, language) || 'accept'}</div>
  //         }
  //         </div>
  //       </label>
  //     </div>
  //   );
  // }

  // if (isUserInSplio) {
  //   return (
  //     <div className="px-3 mb-4 sm:w-full w-1/2 sm:w-full md:w-full">
  //       <label className="flex flex-col" htmlFor="newsletterLink">
  //         <span className="flex">
  //           <span className="uppercase">
  //             <span>{translateObj(newsLetterConfigureText, language)}</span>
  //           </span>
  //           {newsletterSettingsInfo && newsletterSettingsInfo.en && (
  //           <div className="ml-4">
  //             <InfoPopover
  //               text={translateObj(newsletterSettingsInfo, language)}
  //             />
  //           </div>
  //           )}
  //         </span>
  //         <Link
  //           to={`/user-settings/newsletter-preferences?code=${userEmail}`}
  //           localizationpath={`/${language}`}
  //         >
  //           <div className="cursor-pointer rounded-full bg-inherit bg-black uppercase text-white text-16 xs:text-14 sm:text-14 font-americaMonoBold sm:static sm:mx-0 sm:w-full  text-center md:w-full mt-4 px-12 lg:px-16 xl:px-16 py-4 flex items-center  justify-center ">
  //             <div className="font-icon text-white icon-email text-18 -mt-px mr-6" />
  //           </div>
  //         </Link>
  //       </label>
  //     </div>
  //   );
  // }

  return null;
};

const ProfileFormsy = ({
  title,
  fields,
  infoFields,
  validation,
  language,
  setDidSubmit,
  history,
  sourceWeb,
  resendVerificationCallback,
  clickedOnMailVerification,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    userName = '',
    userLastName,
    userEmail = '',
    userNickName,
    userPostalCode,
    userDateOfbirth,
    userCountry,
    loggedFromSocialNetwork,
    isLinkedWithSpotify,
    isUserInNewsLetter,
    isUserInSplio,
    isUserVerified,
    loadTokenForLS = () => {},
  } = useUser();
  const {
    canSubmit, isAddingToNewsLetter, errorMsg,
  } = state;

  const {
    firstName,
    lastName,
    nickName,
    email,
    birthDate,
    country,
    postalCode,
    newsLetterButtonText,
    newsLetterTermsText,
    newsLetterTermsUrl,
    newsLetterSubscribeText,
    newsLetterConfigureText,
    spotifyLinkText,
    spotifyLinkedText,
    spotifyUnLinkText,
  } = fields;
  const {
    firstNameInfo,
    lastNameInfo,
    nickNameInfo,
    emailInfo,
    birthDateInfo,
    countryInfo,
    postalCodeInfo,
    spotifyLinkInfo,
    spotifyLinkedInfo,
    newsletterInfo,
    newsletterSettingsInfo,
  } = infoFields;
  const {
    inValidName,
    longName,
    longEmail,
    inValidEmail,
    invalidPostalCode,
    countryError,
  } = validation;

  const enableButton = () => {
    dispatch({ type: 'canSubmit' });
  };

  const disableButton = () => {
    dispatch({ type: 'cannotSubmit' });
  };


  const handleDate = (name, date) => {
    dispatch({ type: 'setDateOfBirth', dateOfBirth: date });
  };

  const handleButtonTheme = () => {
    if (canSubmit) return { base: 'red', hover: 'red' };
    return { base: 'disabled' };
  };

  const SocialMediaLoginButtons = () => (
    <div className="mb-4 sm:w-full w-1/2 sm:w-full md:w-full">
      <label className="flex flex-col" htmlFor="spotifyLink">
        <span className="flex mb-2">
          <span className="font-stratos text-12 font-light text-black uppercase">
            <span>{translateObj(isLinkedWithSpotify ? spotifyLinkedText : spotifyLinkText, language)}</span>
          </span>
          <InfoPopover classNames="ml-4" text={translateObj(spotifyLinkInfo, language)} />
        </span>
        <div className="flex items-center justify-start">
          <SpotifyLogin
            buttonText=""
            isLinkAccountButton
          />
        </div>
      </label>
    </div>
  );

  // <DatePickerFormsy
  //     title={birthDate}
  //     name="birthDate"
  //     handleDate={handleDate}
  //     existingDate={userDateOfbirth}
  //     language={language}
  //     bolder
  //     required
  //     className="w-1/2 mb-3"
  //     infoText={translateObj(birthDateInfo, language)}
  //   />
  //   {displayNewsLetterFormButton({
  //     isUserInNewsLetter, userEmail, isUserVerified, language, newsLetterTermsUrl, newsLetterTermsText, newsletterInfo, newsLetterButtonText, newsLetterConfigureText, newsletterSettingsInfo, newsLetterSubscribeText, isAddingToNewsLetter, isUserInSplio, dispatch, loadTokenForLS,
  //   })}
  //   {errorMsg && <div className="text-red px-3 uppercase">{translateObj(validation[errorMsg], language)}</div>}

  return (
    <div>
      <Formsy
        className="grid grid-cols-4 tablet:grid-cols-10 laptop:grid-cols-8 gap-3 laptop:gap-4 mb-5"
        onValid={enableButton}
        onInvalid={disableButton}
        onValidSubmit={formData => submitForm(
          formData,
          userDateOfbirth,
          language,
          dispatch,
          loadTokenForLS,
          state.dateOfBirth,
          setDidSubmit,
          history,
          sourceWeb,
        )}
      >
        <div className="col-start-1 tablet:col-start-2 laptop:col-start-auto col-span-4 tablet:col-span-7 laptop:col-span-4">
          <FormInput
            name="userName"
            title={translateObj(firstName, language)}
            validations={{ isSpecialWords: true, maxLength: 50 }}
            validationErrors={{
              isSpecialWords: translateObj(inValidName, language),
              maxLength: translateObj(longName, language),
            }}
            titleClassName="flex uppercase"
            className="w-full"
            doNotChangeValidColor
            required
            value={userName}
            infoText={translateObj(firstNameInfo, language)}
          />
        </div>
        <div className="col-start-1 tablet:col-start-2 laptop:col-start-auto col-span-4 tablet:col-span-7 laptop:col-span-4">
          <FormInput
            name="userLastName"
            title={translateObj(lastName, language)}
            validations={{ isSpecialWords: true, maxLength: 50 }}
            validationErrors={{
              isSpecialWords: translateObj(inValidName, language),
              maxLength: translateObj(longName, language),
            }}
            required
            titleClassName="flex uppercase"
            className="w-full"
            doNotChangeValidColor
            value={userLastName}
            infoText={translateObj(lastNameInfo, language)}
          />
        </div>
        <div className="col-start-1 tablet:col-start-2 laptop:col-start-auto col-span-4 tablet:col-span-7 laptop:col-span-4">
          <FormInput
            name="userNickName"
            title={translateObj(nickName, language)}
            validations={{ maxLength: 15 }}
            validationErrors={{
              maxLength: translateObj(longName, language),
            }}
            titleClassName="flex uppercase"
            className="w-full"
            doNotChangeValidColor
            required
            value={userNickName}
            infoText={translateObj(nickNameInfo, language)}
          />
        </div>
        <div className="col-start-1 tablet:col-start-2 laptop:col-start-auto col-span-4 tablet:col-span-7 laptop:col-span-2">
          <FormSelectAutoComplete
            titleClassName="font-stratos text-12 font-light text-black uppercase"
            name="inputCountry"
            title={translateObj(country, language)}
            value={getCountryFromCode(userCountry, language) || 'Spain'}
            options={getCountryNamesByCode(language)}
            message={translateObj(countryError, language)}
            required
            infoText={translateObj(countryInfo, language)}
          />
        </div>
        <div className="col-start-1 tablet:col-start-2 laptop:col-start-auto col-span-4 tablet:col-span-7 laptop:col-span-2">
          <FormInput
            name="userPostalCode"
            title={translateObj(postalCode, language)}
            validations={{ maxLength: 10 }}
            validationError={translateObj(invalidPostalCode, language)}
            titleClassName="flex uppercase"
            className="w-full"
            doNotChangeValidColor
            value={userPostalCode}
            infoText={translateObj(postalCodeInfo, language)}
          />
        </div>
        <div className="col-start-1 tablet:col-start-2 laptop:col-start-auto col-span-4 tablet:col-span-7 laptop:col-span-4 flex flex-col items-stretch justify-start">
          <FormInput
            name="email"
            title={translateObj(email, language)}
            validations={{ isEmail: true, maxLength: 50 }}
            validationErrors={{
              isEmail: translateObj(inValidEmail, language),
              maxLength: translateObj(longEmail, language),
            }}
            required
            titleClassName="flex uppercase"
            className="w-full"
            doNotChangeValidColor
            value={userEmail}
            infoText={translateObj(emailInfo, language)}
            backgroundColor="beige"
          />
          <div key="verification-status" className="flex items-center justify-end text-red text-12 font-stratos uppercase font-semibold pr-3">
            {isUserVerified ? (
              <>
                {translateObj({
                  en: 'Verificado',
                  es: 'Verificado',
                }, language)}
              </>
            ) : (
              <>
                {clickedOnMailVerification ? (
                  <>
                    {translateObj({
                      en: 'El email de verificación ha sido enviado',
                      es: 'El email de verificación ha sido enviado',
                    }, language)}
                  </>
                ) : (
                  <a
                    href="#"
                    className="text-red"
                    onClick={(e) => {
                      e.preventDefault();
                      resendVerificationCallback();
                    }}
                  >
                    {translateObj({
                      en: 'Enviar email de verificación',
                      es: 'Enviar email de verificación',
                    }, language)}
                  </a>
                )}
              </>
            )}
            <span className={`ml-2 font-icon text-20 ${isUserVerified ? 'icon-check' : 'icon-caution-circle'}`} />
          </div>
        </div>
        <div className="col-start-1 tablet:col-start-2 laptop:col-start-auto col-span-4 tablet:col-span-7 laptop:col-span-4">
          <SocialMediaLoginButtons />
        </div>
        <div className="col-span-3 laptop:col-span-2 col-end-5 tablet:col-end-11 laptop:col-end-9 row-start-9 laptop:row-start-4 cursor-pointer flex flex-col items-stretch justify-end">
          <button
            type="submit"
            className="w-full"
            disabled={!canSubmit}
          >
            <SimpleButton
              isDisabled
              isLowercase
              isFullWidth
              theme={handleButtonTheme()}
              text={{
                en: 'Guardar cambios',
                es: 'Guardar cambios',
              }}
            />
          </button>
        </div>
      </Formsy>
    </div>
  );
};


ProfileFormsy.propTypes = {
  history: PropTypes.shape().isRequired,
  title: PropTypes.shape().isRequired,
  fields: PropTypes.shape().isRequired,
  validation: PropTypes.shape().isRequired,
  infoFields: PropTypes.shape().isRequired,
  setDidSubmit: PropTypes.func.isRequired,
  resendVerificationCallback: PropTypes.func.isRequired,
  language: PropTypes.string,
  sourceWeb: PropTypes.string,
  // menu: PropTypes.shape(),
  // conditions: PropTypes.shape().isRequired,
  // submitButtonText: PropTypes.shape().isRequired,
  // resetButtonText: PropTypes.shape().isRequired,
  // submitMessageButton: PropTypes.shape().isRequired,
};

ProfileFormsy.defaultProps = {
  // menu: null,
  language: 'en',
  sourceWeb: '',
};

export default ProfileFormsy;
