/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect, useContext } from 'react';
import { number, PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import RoutesContext, { useRoutes } from '../../../context/routesContext';
import api from '../Payments/psWorldPaymentsApi';
import getLocalization from '../../../util/localization';
import translateObj from '../../../util/translateObj';
import { translations } from '../Login/LoginUtils/onDemandLoginSimpleTexts';
import SimpleOnDemandModalScreen from '../OnDemand/SimplifiedOnDemandModals/SimpleOnDemandModalScreen';
import { UserProfileContext, useUser } from '../../../context/userProfileContext';
import getProducts from '../../../api/graphql/queries/getProducts';
import userSetPaymentMethods from '../../../api/graphql/mutations/userSetPaymentMethods';
import userSetPreferredPaymentMethod from '../../../api/graphql/mutations/userSetPreferredPaymentMethod';
import getPaymentForProductGroup from '../../../api/graphql/queries/getPaymentForProductGroup';
import userGetPaymentMethods from '../../../api/graphql/queries/userGetPaymentMethods';
import validateCoupon from '../../../api/graphql/queries/validateCoupon';
import refetchUserToken from '../../../api/graphql/mutations/refetchUserToken';
import purchaseProduct from '../../../api/graphql/mutations/purchaseProduct';
import profileUserEdit from '../../../api/graphql/mutations/profileUserEdit';
import PsWorldStripeCardFormWrapper from './StripeCardFormWrapper';
import PayWithStoredCardStripeWrapper from './PayWithStoredCardStripeWrapper';
import PayWithPaypal from './PayWithPaypal';
import StandardFormCheckboxConditions from '../Form/StandardFormCheckboxConditions';

const defaultConditionsText = {
  acceptText: {
    es: 'Acepto las ',
    en: 'Acepto las ',
  },
  conditionsText: {
    es: 'CONDICIONES DE ACCESO',
    en: 'CONDICIONES DE ACCESO',
  },
  conditionsLink: {
    es: '/condiciones-de-registro',
    en: '/condiciones-de-registro',
  },
  dataTreatmentText: {
    es: ' y el tratamiento de datos y la elaboración de perfiles según la ',
    en: ' y el tratamiento de datos y la elaboración de perfiles según la ',
  },
  privacyPolicyText: {
    es: 'POLÍTICA DE PRIVACIDAD',
    en: 'POLÍTICA DE PRIVACIDAD',
  },
  privacyPolicyUrl: {
    es: '/politica-de-privacidad',
    en: '/politica-de-privacidad',
  },
  cancelationPolicy: {
    es: '*Puedes cancelar el plan cuando quieras.',
    en: '*Puedes cancelar el plan cuando quieras.',
  },
  monthPolicy: {
    es: 'A partir del tercer mes, si no has cancelado, se cobrará 3,99€ mensualmente',
    en: 'A partir del tercer mes, si no has cancelado, se cobrará 3,99€ mensualmente',
  },
};

const ProductChosenModalContent = ({
  match, productSlugName, buttonText, parentComponent,
}) => {
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [productGroup, setProductGroup] = useState('');
  const [productData, setProductData] = useState(null);
  const [customerInfo, setCustomerInfo] = useState(null);
  const [customerPaymentMethods, setCustomerPaymentMethods] = useState(null);
  const [customerPaymentMethodsSelectorOptions, setCustomerPaymentMethodsSelectorOptions] = useState([]);
  const [paymentMethodId, setPaymentMethodId] = useState('');
  const [paymentProvider, setPaymentProvider] = useState(false);
  const [paymentActive, setPaymentActive] = useState(null);
  const [showGetCardInfo, setShowGetCardInfo] = useState(false);
  const [couponVisible, setCouponVisible] = useState(false);
  const [couponCode, setCouponCode] = useState('');
  const [validCode, setValidCode] = useState('');
  const [couponValid, setCouponValid] = useState(false);
  const [couponDataFinished, setCouponDataFinished] = useState(false);
  const [couponDescription, setCouponDescription] = useState();
  const [numInitialDaysFree, setNumInitialDaysFree] = useState();
  const [showPaymentFinished, setShowPaymentFinished] = useState(false);
  const [presentSenderName, setPresentSenderName] = useState('');
  const [presentDestinationName, setPresentDestinationName] = useState('');
  const [presentDestinationEmail, setPresentDestinationEmail] = useState('');
  const [presentDeliveryDatePart, setPresentDeliveryDatePart] = useState('');
  const [presentDeliveryHourPart, setPresentDeliveryHourPart] = useState('');
  const [presentDeliveryDate, setPresentDeliveryDate] = useState(null);
  const [presentMessage, setPresentMessage] = useState('');
  const [presentDataFinished, setPresentDataFinished] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [productPurchaseText, setProductPurchaseText] = useState();
  const [newAmount, setNewAmount] = useState();
  const [idNumber, setIdNumber] = useState('');
  const [idNumberIsValid, setIdNumberIsValid] = useState(true);
  const [formError, setFormError] = useState();
  const needsIdNumber = productData && productData.extraFormFields && productData.extraFormFields.includes('idNumber');
  
  const user = useUser();

  const [message, setMessage] = useState('');
  const [finalMessage, setFinalMessage] = useState('');
  const [finalErrorMessage, setFinalErrorMessage] = useState('');
  const [errorDescription, setErrorDescription] = useState();
  const [error, setError] = useState('');

  const userContext = useContext(UserProfileContext);
  const { language = 'en' } = useRoutes(match);
  const selectPaymentMethodScreen = isUserLoggedIn && (!paymentProvider || paymentProvider === 'coupon') && (!needsIdNumber || idNumberIsValid) && !couponVisible;
  const addCreditCardScreen = isUserLoggedIn && paymentProvider === 'stripe' && customerInfo && customerPaymentMethods && customerPaymentMethods.length === 0;
  const sendPresentScreen = isUserLoggedIn && (paymentProvider === 'paypal' || (paymentProvider === 'stripe' && customerInfo && customerPaymentMethods && customerPaymentMethods.length > 0)) && !showPaymentFinished && productData && productData.productPresent && !presentDataFinished;
  const purchaseSummaryScreenStripe = isUserLoggedIn && paymentProvider === 'stripe' && customerInfo && customerPaymentMethods && customerPaymentMethods.length > 0 && !showPaymentFinished && (!productData.productPresent || presentDataFinished);
  const purchaseSummaryScreenPaypal = isUserLoggedIn && paymentProvider === 'paypal' && productData && !showPaymentFinished && (!productData.productPresent || presentDataFinished);
  const promotionalCodeScreen = isUserLoggedIn && couponVisible && !couponDataFinished;

  const translatedTexts = {
    idNumber: {
      en: 'DNI/NIE',
      es: 'DNI/NIE',
    },
    paymentMethod: {
      en: 'Método de pago',
      es: 'Método de pago',
    },
    creditCard: {
      en: 'Tarjeta de crédito',
      es: 'Tarjeta de crédito',
    },
    promotionalCode: {
      en: 'Código promocional',
      es: 'Código promocional',
    },
    step: {
      en: 'Paso',
      es: 'Paso',
    },
    of: {
      en: 'de',
      es: 'de',
    },
    sendGift: {
      en: 'Enviar regalo',
      es: 'Enviar regalo',
    },
    giftedEmail: {
      en: 'Email del obsequiado',
      es: 'Email del obsequiado',
    },
    shippingDay: {
      en: 'Día de envío',
      es: 'Día de envío',
    },
    shippingHour: {
      en: 'Hora de envío',
      es: 'Hora de envío',
    },
    note: {
      en: 'Nota',
      es: 'Nota',
    },
    maxCharacters: {
      en: '(255 caracteres máximo)',
      es: '(255 caracteres máximo)',
    },
    fromWho: {
      en: '¿Quién lo regala?',
      es: '¿Quién lo regala?',
    },
    cancel: {
      en: 'Cancelar',
      es: 'Cancelar',
    },
    giveAway: {
      en: 'Regalar',
      es: 'Regalar',
    },
    purchaseSummary: {
      en: 'Resumen de compra',
      es: 'Resumen de compra',
    },
    monthlyDescription: {
      en: `${userContext.userName} vas a comprar suscripción mensual por 3,99 € al mes`,
      es: `${userContext.userName} vas a comprar suscripción mensual por 3,99 € al mes`,
    },
    monthlyDescriptionFree: {
      en: 'Suscripción mensual gratuita durante {numDays} meses*',
      es: 'Suscripción mensual gratuita durante {numDays} meses*',
    },
    anualDescription: {
      en: `${userContext.userName} vas a comprar suscripción anual por 40 € al año`,
      es: `${userContext.userName} vas a comprar suscripción anual por 40 € al año`,
    },
    creditCardEnded: {
      en: 'Tarjeta de crédito acabada en',
      es: 'Tarjeta de crédito acabada en',
    },
    havePromotionalCode: {
      en: '¿Tienes un código promocional?',
      es: '¿Tienes un código promocional?',
    },
    paypalSoon: {
      en: 'Pronto podrás pagar con Paypal...',
      es: 'Pronto podrás pagar con Paypal...',
    },
    enterCode: {
      en: 'Introduce código',
      es: 'Introduce código',
    },
    useCode: {
      en: 'Usar código',
      es: 'Usar código',
    },
    validateCode: {
      en: 'Validar',
      es: 'Validar',
    },
    welcome: {
      en: 'Te damos la bienvenida',
      es: 'Te damos la bienvenida',
    },
    errorOcured: {
      en: 'Ha habido un error',
      es: 'Ha habido un error',
    },
    giftSent: {
      en: 'Has hecho un regalo a',
      es: 'Has hecho un regalo a',
    },
    message: {
      en: 'Mensaje',
      es: 'Mensaje',
    },
    presentFrom: {
      en: 'Regalo de...',
      es: 'Regalo de...',
    },
    userAlreadySubscribed: {
      en: 'Ya tienes una suscripción activa',
      es: 'Ya tienes una suscripción activa',
    },
  };

  const messageTexts = {
    cardStoredSuccess: {
      en: 'Tarjeta guardada. Selecciónala para pagar',
      es: 'Tarjeta guardada. Selecciónala para pagar',
    },
    cardStoredError: {
      en: 'No se ha podido guardar la tarjeta',
      es: 'No se ha podido guardar la tarjeta',
    },
    validCode: {
      en: 'Código válido',
      es: 'Código válido',
    },
    codeNotFound: {
      en: 'El código no es válido o ya ha sido usado',
      es: 'El código no es válido o ya ha sido usado',
    },
  };

  const paymentProviderClick = (provider) => {
    if (needsIdNumber && !idNumber) {
      setFormError('Debes introducir tu DNI o NIE');
      return;
    }
    profileUserEdit({ email: userContext.userEmail, idNumber });
    setFormError(null);
    setPaymentProvider(provider);
  };

  const getStepsText = (stepNumber) => {
    if (productSlugName) {
      switch (productSlugName) {
        case 'rockdelux-subscription-1-year':
        case 'rockdelux-subscription-1-month':
          return (
            `${translateObj(translatedTexts.step, language)} ${stepNumber} ${translateObj(translatedTexts.of, language)} 2`
          );
        case 'rockdelux-present-subscription-1-year':
          return (
            `${translateObj(translatedTexts.step, language)} ${stepNumber} ${translateObj(translatedTexts.of, language)} 3`
          );
        default:
          return null;
      }
    }
    return null;
  };

  const getProductPurchaseText = () => {
    if (productSlugName) {
      switch (productSlugName) {
        case 'rockdelux-subscription-1-year':
        case 'rockdelux-present-subscription-1-year':
          setProductPurchaseText(translatedTexts.anualDescription);
          break;
        case 'rockdelux-subscription-1-month':
          setProductPurchaseText(translatedTexts.monthlyDescription);
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    if (productSlugName) {
      getProductPurchaseText();
      getProducts([productSlugName]).then((productListReceived) => {
        if (productListReceived && productListReceived.length) {
          const [productReceived] = productListReceived;
          setProductData(productReceived);
          const { productGroupReceived } = productReceived;
          setProductGroup(productGroupReceived);
        }
      });
    }
  }, [productSlugName]);

  useEffect(() => {
    if ((paymentProvider === 'stripe' || paymentProvider === 'coupon') && !customerInfo && userContext.userEmail) {
      const cleanUserEmail = userContext.userEmail.trim();
      api.getOrCreateCustomer(cleanUserEmail).then((customerData) => {
        setCustomerInfo(customerData);
        if (!customerData || !Object.keys(customerData).length) {
          setMessage(`Customer ${userContext.userEmail} could not be retrieved nor created`);
        }
      });
    }
  }, [paymentProvider, userContext.userEmail, customerInfo]);

  useEffect(() => {
    if (productData && productData.productGroup) {
      getPaymentForProductGroup(productData.productGroup).then((payment) => {
        if (payment) {
          setPaymentActive(payment);
        }
      });
    }
  }, [productData]);

  // Handle payment card selection
  useEffect(() => {
    if (customerPaymentMethods) {
      const options = customerPaymentMethods.map((paymentOption) => {
        const {
          token, card: { last4 }, card: { exp_month }, card: { exp_year },
        } = paymentOption;
        // return { value: id, label: last4 };
        // return `<option value="${token}">**** **** **** ${last4}</option>`;
        return (
          <option key={token} value={token}>
            **** **** ****
            {' '}
            {last4}
            {' '}
            {exp_month}
            /
            {exp_year}
          </option>
        );
      });
      setCustomerPaymentMethodsSelectorOptions(options);
      const preferredMethod = customerPaymentMethods.find(method => method.preferred === true);
      if (preferredMethod) {
        setPaymentMethodId(preferredMethod.token);
      } else if (customerPaymentMethods.length) {
        setPaymentMethodId(customerPaymentMethods[0].token);
      }
    }
  }, [customerPaymentMethods]);

  useEffect(() => {
    const logged = !!userContext.userEmail;
    setIsUserLoggedIn(logged);

    if (!logged) {
      return;
    }

    userGetPaymentMethods().then(paymentMethods => setCustomerPaymentMethods(paymentMethods));
  }, [userContext]);

  useEffect(() => {
    if (finalMessage) {
      // console.log('Subscription finished', productData.slugName, newAmount || productData.amount);
      const tagManagerArgs = {
        dataLayer: {
          // ecommerce: {
          //   currencyCode: 'EUR', // Local currency is optional.
          //   products: [
          //     {
          //       name: productData.title.en,
          //       id: productData.slugName,
          //       category: productData.productType,
          //       price: newAmount === 0 ? 0 : newAmount || productData.amount, // newAmount can be 0 or undefined!
          //       quantity: 1,
          //       coupon: couponCode,
          //     }],
          // },
          event: 'subscriptionFinished',
          product: productData.slugName,
          price: newAmount === 0 ? 0 : newAmount || productData.amount, // newAmount can be 0 or undefined!
          email: userContext.userEmail.trim(),
          pageUrl: 'suscribirse-fin',
          path: '/suscribirse-fin',
        },
        // dataLayerName: 'subscriptions',
      };

      // window.dataLayer = window.dataLayer || [];
      // window.dataLayer.push({
      //   event: 'subscriptionFinished',
      //   product: productData.slugName,
      //   price: newAmount === 0 ? 0 : newAmount || productData.amount, // newAmount can be 0 or undefined!
      //   email: userContext.userEmail.trim(),
      //   pageUrl: 'suscribirse-fin',
      //   path: '/suscribirse-fin',
      // });
      TagManager.dataLayer(tagManagerArgs);
      console.log('🚀 ~ file: ProductChosenModalContent.jsx ~ line 365 ~ useEffect ~ tagManagerArgs', tagManagerArgs);
    }
  }, [finalMessage]);

  useEffect(() => {
    if (presentDeliveryDatePart && presentDeliveryHourPart) {
      const date = new Date(`${presentDeliveryDatePart} ${presentDeliveryHourPart}`);
      if (date instanceof Date && !Number.isNaN(date.valueOf())) {
        setPresentDeliveryDate(date);
      }
    }
  }, [presentDeliveryDatePart, presentDeliveryHourPart]);

  const closeLoginHandler = () => {
    // Do nothing?
  };

  const getCustomerPaymentMethodsFromStripe = async () => {
    setError('');
    setMessage('');
    if (!userContext.userEmail) {
      setError('Please log in to get payment methods');
      return;
    }
    if (!customerInfo || !customerInfo.id) {
      setError('Please get customer info first');
      return;
    }

    // Get payment methods from api (stripe)
    api.getCustomerPaymentMethods(customerInfo.id).then(async (paymentMethods) => {
      // if (!paymentMethods || !paymentMethods.length) {
      //   setMessage(`No Customer payent methods info for ${userContext.userEmail}`);
      // }
      // Save payment methods to back:
      // [{"id":"pm_1HbiKRLnN1U6bnmqIF8oNpXa","object":"payment_method","billing_details":{"address":{"city":null,"country":null,"line1":null,"line2":null,"postal_code":null,"state":null},"email":null,"name":null,"phone":null},"card":{"brand":"visa","checks":{"address_line1_check":null,"address_postal_code_check":null,"cvc_check":"pass"},"country":"US","exp_month":12,"exp_year":2021,"fingerprint":"Urj9nfSO3phdXZQ9","funding":"credit","generated_from":null,"last4":"4242","networks":{"available":["visa"],"preferred":null},"three_d_secure_usage":{"supported":true},"wallet":null},"created":1602575632,"customer":"cus_HJ3wQPaIeVWSGb","livemode":false,"metadata":{},"type":"card"},{"id":"pm_1GlWqNLnN1U6bnmqoyCK732r","object":"payment_method","billing_details":{"address":{"city":null,"country":null,"line1":null,"line2":null,"postal_code":null,"state":null},"email":null,"name":null,"phone":null},"card":{"brand":"visa","checks":{"address_line1_check":null,"address_postal_code_check":null,"cvc_check":"pass"},"country":"US","exp_month":4,"exp_year":2024,"fingerprint":"Urj9nfSO3phdXZQ9","funding":"credit","generated_from":null,"last4":"4242","networks":{"available":["visa"],"preferred":null},"three_d_secure_usage":{"supported":true},"wallet":null},"created":1590138427,"customer":"cus_HJ3wQPaIeVWSGb","livemode":false,"metadata":{},"type":"card"}]
      // id, card.last4, card.brand, card.exp_month, card.exp_year

      const paymentMethodsSend = paymentMethods.map((method) => {
        const {
          id: token,
          card: {
            last4, brand, exp_month, exp_year,
          },
          created,
        } = method;

        const createdAt = new Date(created * 1000);

        return {
          provider: 'stripe',
          token,
          card: {
            last4,
            brand,
            exp_month,
            exp_year,
          },
          createdAt,
        };
      });
      // Save payment methods in our database
      await userSetPaymentMethods({ provider: 'stripe', paymentMethods: paymentMethodsSend }); // TODO: get the preferred method from response and set it with setPaymentMethodId()
      setCustomerPaymentMethods(paymentMethodsSend);
    });
  };

  const setPaymentMethodHandler = async (newPaymentMethodId) => {
    setPaymentMethodId(newPaymentMethodId);
    const result = userSetPreferredPaymentMethod({ token: newPaymentMethodId });
    // setMessage(result.error);
  };

  const storeNewCardResult = (result) => {
    const { status } = result;

    if (status === 'cancel') {
      setPaymentProvider(false);
      return;
    }

    if (status === 'succeeded') {
      setShowGetCardInfo(false);
      getCustomerPaymentMethodsFromStripe();
      setMessage(translateObj(messageTexts.cardStoredSuccess, language));
      return;
    }

    setMessage(translateObj(messageTexts.cardStoredError, language));
  };

  const redeemCoupon = () => {
    if (newAmount === 0) {
      purchaseProduct({
        productSlugName,
        couponCode,
      }).then((response) => {
        if (response.result) {
          setShowPaymentFinished(true);
          setCouponDataFinished(true);
          refetchUserToken().then((userData) => {
            if (userData.token) {
              user.setNewAuthToken(userData.token); // This closes the finalMessage screen
              return userData;
            }
          }).catch((err) => {
            console.log('Some error happened after paying at refetchUserToken:', err);
          });
          setFinalMessage(translateObj(translatedTexts.welcome, language));
        } else if (response.error) {
          setFinalErrorMessage(translateObj(translatedTexts.errorOcured, language));
          setErrorDescription(response.error);
          setShowPaymentFinished(true);
          setCouponDataFinished(true);
        }
      });
    } else {
      setCouponDescription(translateObj({
        en: 'Selecciona el método de pago "Tarjeta de crédito" para introducir tu código y aplicar el descuento.',
        es: 'Selecciona el método de pago "Tarjeta de crédito" para introducir tu código y aplicar el descuento.',
      }, language));
    }
  };

  const payWithStoredCardResult = (result) => {
    const { status } = result;
    if (status === 'cancel') {
      setPresentDataFinished(false);
      setPaymentProvider(false);
      setCouponDataFinished(false);
      return;
    }

    if (status === 'succeeded') {
      setShowPaymentFinished(true);
      setCouponDataFinished(true);
      if (productData.productPresent) {
        setFinalMessage(`${translateObj(translatedTexts.giftSent, language)} ${presentDestinationEmail}`);
      } else {
        refetchUserToken().then((response) => {
          if (response.token) {
            user.setNewAuthToken(response.token); // This closes the finalMessage screen
            return response;
          }
        }).catch((err) => {
          console.log('Some error happened after paying at refetchUserToken:', err);
        });
        setFinalMessage(translateObj(translatedTexts.welcome, language));
      }
      return;
    }
    setShowPaymentFinished(true);
    setFinalErrorMessage(translateObj(translatedTexts.errorOcured, language));
    setErrorDescription(`${result.stripeError}: ${status}`);
  };

  const numInitialMonthsFree = (numDays) => {
    if (typeof (numDays) !== 'number') return undefined;
    const months = Math.floor(numDays / 30);
    return months;
  };

  // This event is triggered when user is at the payment screen
  const handleValidateCoupon = async () => {
    const result = await validateCoupon({ couponCode, productSlugName });
    if (result.valid && result.paymentNecessary) {
      // Show payment modal, also if product is free but requires card registration
      setCouponVisible(false);
      setNewAmount(result.newAmount);
      setCouponValid(true);
      setPaymentProvider('stripe');
      setProductPurchaseText(translateObj(translatedTexts.monthlyDescriptionFree, language).replace('{numDays}', numInitialMonthsFree(result.numInitialDaysFree)));
      setNumInitialDaysFree(result.numInitialDaysFree);
      return;
    }
    setError(!result.valid);
    setMessage(result.valid ? translateObj(messageTexts.validCode, language) : translateObj(messageTexts.codeNotFound, language));
    setNewAmount(result.newAmount);
    setValidCode(result.valid ? couponCode : '');
    setCouponValid(result.valid);
    setNumInitialDaysFree(null);
  };

  // // This event is triggered when user is at the promotionalCodeScreen
  // const handleValidateCouponAtCouponScreen = async () => {
  //   const result = await validateCoupon({ couponCode, productSlugName });
  //   if (result.valid && result.paymentNecessary) {
  //     // Show payment modal, also if product is free but requires card registration
  //     setCouponVisible(false);
  //     setNewAmount(result.newAmount);
  //     setCouponValid(true);
  //     setPaymentProvider('stripe');
  //     setProductPurchaseText(translateObj(translatedTexts.monthlyDescriptionFree, language).replace('{numDays}', result.numInitialDaysFree));
  //     return;
  //   }
  //   setError(!result.valid);
  //   setMessage(result.valid ? translateObj(messageTexts.validCode, language) : translateObj(messageTexts.codeNotFound, language));
  //   setNewAmount(result.newAmount);
  //   setValidCode(result.valid ? couponCode : '');
  //   setCouponValid(result.valid);
  // };

  const handleCheckBoxValue = (isChecked) => {
    if (isChecked) {
      setIsFilled(true);
    } else {
      setIsFilled(false);
    }
  };

  return (
    <RoutesContext.Consumer>
      {({ language: contextLanguage, enteredFrom }) => {
        const localization = getLocalization(contextLanguage, enteredFrom, match);
        if (!productData) return '';
        return (
          <>
            <div className="border-b border-grey pb-4">
              <h1 class="font-stratos font-bold text-15 text-black">{productData?.title?.en}</h1>
            </div>
            {/* SELECCIONAR METODO DE PAGO */}
            {selectPaymentMethodScreen && (
              <div className="flex flex-col items-center pt-18">
                { productData?.extraFormFields && productData.extraFormFields.includes('idNumber') && (
                  <label className="text-10 font-stratos text-black font-normal uppercase w-full">
                    {translateObj(translatedTexts.idNumberTitle, language)}
                    <input type="text" placeholder={translateObj(translatedTexts.idNumber, language)} className="leading-22 font-stratos text-15 font-normal text-black w-full border border-black my-2 h-12 p-3" value={idNumber} onChange={newValue => setIdNumber(newValue.currentTarget.value)} />
                  </label>
                )
                }
                { formError && (
                  <div className="flex flex-col items-center w-full">
                    <span className="font-icon icon-caution-circle text-red mr-2" />
                    <span className="font-stratos text-red text-15 font-normal break-words">
                      {formError}
                    </span>
                  </div>
                )
                }
                <h3 className="text-black font-stratos text-18 font-bold text-center uppercase mb-10 mt-5">
                  {translateObj(translatedTexts.paymentMethod, language)}
                </h3>
                <button className="text-red font-stratos uppercase font-bold text-18 border-8 border-red py-2 w-60 mb-7" type="button" onClick={() => paymentProviderClick('stripe')}>{translateObj(translatedTexts.creditCard, language)}</button>
                { productData?.paypal && <button className="text-red font-stratos uppercase font-bold text-18 border-8 border-red py-2 w-60 mb-7" type="button" onClick={() => { setPaymentProvider('paypal'); setIsFilled(false); }}>Paypal</button>}
                { !productData?.hideCouponField && <button className="text-red font-stratos uppercase font-bold text-18 border-8 border-red py-2 w-60" type="button" onClick={() => { setPaymentProvider('coupon'); setCouponVisible(true); }}>{translateObj(translatedTexts.promotionalCode, language)}</button>}
              </div>
            )}

            {/* TARJETA DE CREDITO */}
            {(!isUserLoggedIn && <SimpleOnDemandModalScreen {...userContext} language={language} translations={translations} closeModal={closeLoginHandler} parentComponent={parentComponent} />)}
            {addCreditCardScreen && (
              <div className="flex flex-col items-center justify-between">
                <span className="uppercase font-stratos text-18 font-bold text-black">{getStepsText(1)}</span>
                <h1 className="uppercase font-stratos text-18 font-bold text-red">{translateObj(translatedTexts.creditCard, language)}</h1>
                <div className="mt-6">
                  <PsWorldStripeCardFormWrapper customerInfo={customerInfo} product={productData} resultCallback={storeNewCardResult} />
                </div>
              </div>
            )}

            {/* ENVIAR REGALO */}
            {sendPresentScreen && (
              <div className="flex flex-col items-center w-full">
                <span className="uppercase font-stratos text-18 font-bold text-black">{getStepsText(2)}</span>
                <h1 className="uppercase font-stratos text-18 font-bold text-red">{translateObj(translatedTexts.sendGift, language)}</h1>
                <div className="mt-2">
                  {/* <input type="text" placeholder="Nombre del destinatario" className="px-6 mr-4 sm:mr-0 font-americaMonoRegular text-12 rounded-full border-1 p-4 bg-inherit border-black text-black text-12 sm:w-92 sm:w-full xl:w-92 lg:w-92 md:w-92" value={presentDestinationName} onChange={newValue => setPresentDestinationName(newValue.currentTarget.value)} /> */}
                  <label className="text-10 font-stratos text-black font-normal uppercase w-full">
                    {translateObj(translatedTexts.giftedEmail, language)}
                    <input type="text" placeholder={translateObj(translatedTexts.giftedEmail, language).toLowerCase()} className="leading-22 font-stratos text-15 font-normal text-black w-full border border-black my-2 h-12 p-3" value={presentDestinationEmail} onChange={newValue => setPresentDestinationEmail(newValue.currentTarget.value)} />
                  </label>
                  <div className="flex flex-row justify-between">
                    <label className="text-10 font-stratos text-black font-normal uppercase w-30">
                      {translateObj(translatedTexts.shippingDay, language)}
                      <input type="date" placeholder="dd-mm-yyyy" className="leading-22 font-stratos text-15 font-normal text-black w-full border border-black my-2 h-12 p-3 flex-1" value={presentDeliveryDatePart} onChange={newValue => setPresentDeliveryDatePart(newValue.currentTarget.value)} />
                    </label>
                    <label className="text-10 font-stratos text-black font-normal uppercase w-30">
                      {translateObj(translatedTexts.shippingHour, language)}
                      <input type="time" placeholder="hh:mm" className="leading-22 font-stratos text-15 font-normal text-black w-full border border-black my-2 h-12 p-3 flex-1" value={presentDeliveryHourPart} onChange={newValue => setPresentDeliveryHourPart(newValue.currentTarget.value)} />
                    </label>
                  </div>
                  <label className="text-10 font-stratos text-black font-normal uppercase w-full">
                    {translateObj(translatedTexts.note, language)}
                    {' '}
                    <span className="lowercase">{translateObj(translatedTexts.maxCharacters, language)}</span>
                    <textarea
                      style={{ resize: 'none' }}
                      placeholder={translateObj(translatedTexts.message, language)}
                      onChange={newValue => setPresentMessage(newValue.currentTarget.value)}
                      value={presentMessage}
                      className="leading-22 font-stratos text-15 font-normal text-black w-full border border-black mt-2 mb-1 h-44 overflow-auto p-3"
                    />
                  </label>
                  <label className="text-10 font-stratos text-black font-normal uppercase w-full">
                    {translateObj(translatedTexts.fromWho, language)}
                    <input type="text" placeholder={translateObj(translatedTexts.presentFrom, language)} className="leading-22 font-stratos text-15 font-normal text-black w-full border border-black my-2 h-12 p-3" value={presentSenderName} onChange={newValue => setPresentSenderName(newValue.currentTarget.value)} />
                  </label>
                </div>
                <div className="flex flex-row w-full justify-between items-center">
                  <button type="button" onClick={() => setPaymentProvider(false)} className="text-grey text-14 font-bold font-stratos px-4 w-32 h-9 border-6 border-grey bg-beige">
                    {translateObj(translatedTexts.cancel, language)}
                  </button>
                  <button type="button" className="text-red text-14 font-bold font-stratos px-4 w-32 h-9 border-6 border-red" onClick={() => setPresentDataFinished(true)}>
                    {translateObj(translatedTexts.giveAway, language)}
                  </button>
                </div>
              </div>
            )}

            {/* PROCES DE COMPRA STRIPE */}
            {purchaseSummaryScreenStripe && (
              <div className="flex flex-col items-center">
                <span className="uppercase font-stratos text-18 font-bold text-black">{productSlugName && productSlugName === 'rockdelux-present-subscription-1-year' ? getStepsText(3) : getStepsText(2)}</span>
                <h1 className="uppercase font-stratos text-18 font-bold text-red">{translateObj(translatedTexts.purchaseSummary, language)}</h1>
                <div className="my-5 text-center">
                  <span className="font-stratos text-20 text-black font-semibold leading-28">{translateObj(productPurchaseText, language)}</span>
                </div>
                <div>
                  <div>
                    <span className="text-10 font-stratos text-black font-regular uppercase">{translateObj(translatedTexts.creditCardEnded, language)}</span>
                    <select className="border border-black p-3 w-full text-15 mt-2 h-12" value={paymentMethodId} onChange={newValue => setPaymentMethodHandler(newValue.currentTarget.value)}>
                      {customerPaymentMethodsSelectorOptions}
                    </select>
                  </div>
                  {!productData?.hideCouponField && (
                    <div className="mt-3">
                      <span className="text-10 font-stratos text-black font-regular uppercase">{translateObj(translatedTexts.havePromotionalCode, language)}</span>
                      <input type="text" placeholder={translateObj(translatedTexts.promotionalCode, language).toLowerCase()} onChange={e => setCouponCode(e.target.value)} className={`${error ? 'text-red border-3 border-red' : 'text-black border border-black'} w-full h-12 px-3 font-stratos text-15 leading-22 font-normal mt-2`} value={couponCode} />
                    </div>
                  )
                  }
                  <StandardFormCheckboxConditions
                    getCheckBoxValue={handleCheckBoxValue}
                    required
                    name="conditions"
                    acceptText={translateObj(defaultConditionsText.acceptText, language)}
                    conditionsText={translateObj(defaultConditionsText.conditionsText, language)}
                    conditionsLink={translateObj(defaultConditionsText.conditionsLink, language)}
                    dataTreatmentText={translateObj(defaultConditionsText.dataTreatmentText, language)}
                    privacyPolicyText={translateObj(defaultConditionsText.privacyPolicyText, language)}
                    privacyPolicyUrl={translateObj(defaultConditionsText.privacyPolicyUrl, language)}
                    validationError=""
                    cancelationPolicy={productData?.subscriptionRenewAuto && translateObj(defaultConditionsText.cancelationPolicy, language)}
                    monthPolicy={numInitialDaysFree && translateObj(defaultConditionsText.monthPolicy, language)}
                  />
                  <PayWithStoredCardStripeWrapper
                    customerInfo={customerInfo}
                    paymentMethodId={paymentMethodId}
                    product={productData}
                    resultCallback={payWithStoredCardResult}
                    validateCouponCallback={handleValidateCoupon}
                    redeemCouponCallback={redeemCoupon}
                    newAmount={newAmount}
                    couponCode={couponCode}
                    couponValid={couponValid}
                    validCode={validCode}
                    presentData={{
                      presentSenderName,
                      presentDestinationName,
                      presentDestinationEmail,
                      presentDeliveryDate,
                      presentMessage,
                    }}
                    conditionsAccepted={isFilled}
                  />
                </div>
              </div>
            )}

            {/* PROCES DE COMPRA PAYPAL */}
            {purchaseSummaryScreenPaypal && (
              <div className="flex flex-col items-center">
                <span className="uppercase font-stratos text-18 font-bold text-black">{productSlugName && productSlugName === 'rockdelux-present-subscription-1-year' ? getStepsText(3) : getStepsText(2)}</span>
                <h1 className="uppercase font-stratos text-18 font-bold text-red">{translateObj(translatedTexts.purchaseSummary, language)}</h1>
                <div className="my-5 text-center">
                  <span className="font-stratos text-20 text-black font-semibold leading-28">{translateObj(productPurchaseText, language)}</span>
                </div>
                <PayWithPaypal
                  userContext={userContext}
                  product={productData}
                  resultCallback={payWithStoredCardResult}
                  validateCouponCallback={handleValidateCoupon}
                  acceptConditionsCallback={handleCheckBoxValue}
                  redeemCouponCallback={redeemCoupon}
                  newAmount={newAmount}
                  couponCode={couponCode}
                  couponValid={couponValid}
                  setCouponCode={setCouponCode}
                  validCode={validCode}
                  translatedTexts={translatedTexts}
                  defaultConditionsText={defaultConditionsText}
                  presentData={{
                    presentSenderName,
                    presentDestinationName,
                    presentDestinationEmail,
                    presentDeliveryDate,
                    presentMessage,
                  }}
                  conditionsAccepted={isFilled}
                />
              </div>
            )}

            {/* PAYPAL */}
            {/* {paypalScreen && (
              <div className="flex flex-col items-center justify-center w-full h-full">
                <span className="text-base font-normal font-stratos text-black pb-4">{translateObj(translatedTexts.paypalSoon, language)}</span>
                <PayWithPaypal
                  customerInfo={customerInfo}
                  product={productData}
                  // resultCallback={storePaypalResult}
                />
                <button type="button" onClick={() => setPaymentProvider(false)} className="text-grey text-14 font-bold font-stratos px-4 w-32 h-9 border-6 border-grey bg-beige">
                  {translateObj(translatedTexts.cancel, language)}
                </button>
              </div>
            )} */}

            {/* CODIGO PROMOCIONAL */}
            {promotionalCodeScreen && (
              <div className="flex flex-col items-center justify-between pt-3">
                <span className="uppercase font-stratos text-18 font-bold text-black">{`${translateObj(translatedTexts.validateCode, language)}`}</span>
                <h1 className="uppercase font-stratos text-18 font-bold text-red">{translateObj(translatedTexts.promotionalCode, language)}</h1>
                <div className="mt-8 relative">
                  <h5 className="text-14 font-semibold font-stratos text-black self-start pb-3">{translateObj(translatedTexts.enterCode, language)}</h5>
                  <input type="text" placeholder={translateObj(translatedTexts.promotionalCode, language).toLowerCase()} onChange={e => setCouponCode(e.target.value)} className={`${error ? 'text-red border-3 border-red' : 'text-black border border-black'} w-64 h-12 px-3 font-stratos text-15 font-normal`} value={couponCode} />
                  <div className="absolute">
                    <span className={`${error ? 'text-red' : 'text-grey'} font-stratos text-15 font-normal block pt-4`}>{message}</span>
                    {couponDescription && <span className="font-stratos text-14 font-normal text-grey pt-4">{couponDescription}</span>}
                  </div>
                </div>
                <div className="flex flex-row w-full justify-between items-center mt-300px">
                  <button type="button" onClick={() => setCouponVisible(false)} className="text-grey text-14 font-bold font-stratos px-4 w-32 h-9 border-6 border-grey bg-beige">
                    {translateObj(translatedTexts.cancel, language)}
                  </button>
                  <button type="button" className="text-red text-14 font-bold font-stratos px-4 w-32 h-9 border-6 border-red" onClick={couponValid && (couponCode === validCode) ? () => redeemCoupon() : handleValidateCoupon}>
                    {couponValid && (couponCode === validCode)
                      ? translateObj(translatedTexts.useCode, language)
                      : translateObj(translatedTexts.validateCode, language)
                    }
                  </button>
                </div>
              </div>
            )}

            <div className="mt-4">
              {/* Show any error that happens when processing the payment */}
              {/* {error && (
                error
              )} */}
              {finalMessage && (
                <div className="flex flex-col items-center w-full">
                  <div className="flex flex-row justify-between items-start w-full">
                    <div className="font-icon icon-check-circle text-red text-40 mr-4" />
                    <div className={`font-stratos text-red ${productData.productPresent === true ? 'text-24 max-w-13' : 'text-36'} font-bold break-words`}>
                      {finalMessage}
                    </div>
                  </div>
                  {productData.productPresent === true ? (
                    <div className="font-stratos text-black text-20 font-medium pt-6">
                        ¡Muchas gracias por difundir la palabra Rockdelux!
                      <br />
                        La persona que reciba el obsequio de esta suscripción podrá disfrutar, a diario y sin restricciones, de todos nuestros contenidos.
                      <br />
                        Dile que la envidiamos: las amistades que contribuyen a tener un medio cultural profesional y de calidad son las mejores amistades.
                    </div>
                  ) : (
                    <div className="font-stratos text-black text-20 font-medium pt-6">
                        ¡Muchas gracias por suscribirte a Rockdelux!
                      <br />
                        Ya puedes disfrutar, a diario y sin restricciones, de todo nuestro contenido y relajarte hasta mañana: contribuir a tener un medio cultural profesional y de calidad no tiene rival como buena acción del día.
                    </div>
                  )}
                </div>
              )}
              {finalErrorMessage && (
                <div className="flex flex-col items-center w-full">
                  <div className="flex flex-row justify-between items-start w-full">
                    <div className="font-icon icon-caution-circle text-red text-40 mr-4" />
                    <div className="font-stratos text-red text-36 font-bold break-words">
                      {finalErrorMessage}
                    </div>
                  </div>
                  <div className="font-stratos text-black text-20 font-medium pt-6">{errorDescription}</div>
                </div>
              )}
            </div>
          </>
        );
      }}
    </RoutesContext.Consumer>
  );
};

export default withRouter(ProductChosenModalContent);

ProductChosenModalContent.propTypes = {
  productSlugName: PropTypes.string.isRequired,
  match: PropTypes.shape().isRequired,
  buttonText: PropTypes.shape().isRequired,
};

ProductChosenModalContent.defaultProps = {
};
